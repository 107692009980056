import React, { useEffect, useState, useCallback } from "react";
import {
    Button,
    Grid,
    Card,
    CardContent,
    Box,
    Typography,
    CardMedia,
    CircularProgress,
    Avatar,
    Tooltip,
    useTheme,
} from "@mui/material";

import {
    FaEuroSign,
    FaExternalLinkAlt,
    FaFileWord,
    FaDoorOpen,
    FaClock,
    FaCar,
} from "react-icons/fa";
import {
    formatDate,
    handoverToAgentAPI,
    updatePropertyAPI,
    getLocalizedLabels,
    formatPrice,
    createExtraServicesAPI,
} from "services";
import { typographyStyles } from "components/styles";

import { LocationOnOutlined } from "@mui/icons-material";
import moment from "moment";
import { ExtraServicesForm } from "./PropertyExtraServicesForm";
import buyImg from "assets/images/buy.png";
import rentImg from "assets/images/rent.png";
import immoNetImg from "assets/images/App-Immonet.png";
import immoScoutImg from "assets/images/App-Immoscout24.png";
import immoWeltImg from "assets/images/App-Immowelt.png";

import { useNavigate } from "react-router-dom";
import { LABELS } from "./constants";
import { type Property } from "@interfaces";
import "./style.css";
import "scss/component/custom.css";
import { buttonStyles } from "components/styles/button";

import { CardRowItem, CardRow } from "./BasicComponents";
import {
    DisplayDialog,
    type DisplayDialogProps,
    defaultDisplayDialog,
} from "components/shared/DisplayDialog";
import { type ChangeInputEvent } from "@types";
import { isAxiosError } from "axios";
import { defaultPropertyImageUrl } from "services/img-utils";
import AppRouter, { MANAGE_SUBSCRIPTION_ROUTE } from "Router";
import { StepTwelveLabel } from "./forms/Step12/StepTwelveLabel";
import { PropertyStatusBadge } from "components/status-badge/PropertyStatusBadge";
import { isLessThanTwoMinutesInThePast } from "services/date-utils";

interface PrivatePropertySummaryProps {
    property: Property;
    isOwner: boolean;
    refetchProperties: () => Promise<void>;
}

export const PrivatePropertySummary = ({
    property,
    isOwner,
    refetchProperties,
}: PrivatePropertySummaryProps) => {
    const { customColors } = useTheme();
    const navigate = useNavigate();
    const labels = getLocalizedLabels(LABELS);
    const [loading, setLoading] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [dialog, setDialog] =
        useState<DisplayDialogProps>(defaultDisplayDialog);
    const [extraServices, setExtraServices] = useState<number[]>([]);

    const imageUrl =
        property.images && property.images.length > 0
            ? property.images[0].url
            : defaultPropertyImageUrl;

    const togglePropertyActiveState = async () => {
        setLoading((_) => true);
        if (!property.id) return;
        await updatePropertyAPI(property.id, {
            ...property,
            is_active: !property.is_active,
        });
        await refetchProperties();
        setLoading((_) => false);
    };

    const archiveProperty = async () => {
        setLoading((_) => true);
        if (!property.id) return;
        await updatePropertyAPI(property.id, {
            ...property,
            archived: true,
            is_active: false,
        });
        handleCloseDialog();
        await refetchProperties();
        setLoading((_) => false);
    };

    const requestServices = async (extraServices: number[]) => {
        if (!property.id) return;
    };
    const handleCloseDialog = () => {
        setDialog(defaultDisplayDialog);
    };

    const openArchiveDialog = () => {
        setDialog((prev) => ({
            ...prev,
            isOpen: true,
            id: "archive",
            confirmElement: (
                <Button
                    sx={buttonStyles.privateProperty}
                    onClick={async () => {
                        await archiveProperty();
                    }}
                >
                    {loading && <CircularProgress sx={{ mr: "1rem" }} />}
                    Ja, archivieren
                </Button>
            ),
            title: "Bestätigung erforderlich",
            subtitle:
                "Möchten Sie wirklich das Inserat archivieren ? Sie können es danach nicht wieder online stellen.",
            onClose: handleCloseDialog,
        }));
    };

    const handleExtraServicesChange = (e: ChangeInputEvent, saleId: number) => {
        if (extraServices.includes(saleId)) {
            const newServicesArray = extraServices.filter(
                (service) => service !== saleId,
            );
            setExtraServices(newServicesArray);
        } else {
            setExtraServices((prev) => [...prev, saleId]);
        }
    };

    const goToProfile = () => {
        navigate(`${MANAGE_SUBSCRIPTION_ROUTE}&propertyId=${property.id}`);
    };
    const requestSalesHelp = async () => {
        if (!property.id) return;
        const res = await handoverToAgentAPI(property.id);
        if (!isAxiosError(res)) {
            alert(
                "Vielen Dank! Wir haben Ihre Anfrage erhalten und werden uns in Kürze bei Ihnen melden!",
            );
            handleCloseDialog();
            await refetchProperties();
        }
    };

    const openSalesHelpDialog = () => {
        setDialog((prev) => ({
            ...prev,
            isOpen: true,
            id: "sales-help",
            confirmElement: (
                <Button
                    sx={{ ...buttonStyles.privateProperty }}
                    onClick={requestSalesHelp}
                >
                    Unverbindlich Anfragen
                </Button>
            ),
            title: "Hilfe beim Verkauf",
            subtitle:
                "Bestätigen Sie nachfolgend, dass Sie Hilfe beim Verkauf anfordern möchten. Wir werden uns darauf hin in kürze bei Ihnen melden.",
            onClose: handleCloseDialog,
        }));
    };

    const getSubscriptionStatus = (): string => {
        const info = property.subscription_info;
        if (!info) return "N/A";
        return info.subscription_summary.status;
    };

    const getActiveText = () => {
        return "Aktiv";
    };

    const getDeactivatedText = () => {
        return "Deaktiviert";
    };

    const getDeactivatedTooltipComponent = () => {
        const deactivatedReasons = [];
        if (!property.immoscout_public_url)
            deactivatedReasons.push(
                "Das Inserat ist nicht auf Immoscout24 veröffentlicht.",
            );
        if (!property.immowelt_listing_id)
            deactivatedReasons.push(
                "Das Inserat ist nicht auf Immowelt veröffentlicht.",
            );
        if (!property.is_active)
            deactivatedReasons.push("Das Inserat ist nicht aktiv.");
        if (property.subscription_info) {
            const { subscription_summary } = property.subscription_info;
            deactivatedReasons.push(
                `Abo Status: ${subscription_summary.status}`,
            );
        }
        if (property.archived)
            deactivatedReasons.push("Das Inserat ist archiviert.");
        return (
            <Box>
                {deactivatedReasons.map((reason) => (
                    <Typography key={reason}>{reason}</Typography>
                ))}
            </Box>
        );
    };

    const getActiveTooltipText = () => {
        const { subscription_info } = property;
        if (subscription_info) {
            if (subscription_info.subscription_summary.cancel_at_period_end)
                return "Kündigung vorgemerkt: Abo wird am Ende des Abrechnungszeitraums gekündigt";
            if (subscription_info.subscription_summary.pause_collection)
                return "Sie haben Ihr Abo pausiert. Es fallen keine weiteren Kosten an.";
            if (
                subscription_info.subscription_summary.status === "active" &&
                !property.immoscout_public_url
            ) {
                return "Bitte haben Sie noch einen Moment Geduld. Wir sind dabei, Ihr Inserat zu veröffentlichen.";
            }
            return "Abo aktiv";
        }
    };

    const isMarkedForCancelation =
        property.subscription_info &&
        property.subscription_info.subscription_summary.cancel_at_period_end;

    const isCancelled =
        property.subscription_info?.subscription_summary.status === "canceled";

    const getPropertyStatusDisplay = (): React.ReactNode => {
        if (
            getSubscriptionStatus() === "active" &&
            property.immoscout_public_url
        ) {
            return (
                <PropertyStatusBadge
                    tooltipText={getActiveTooltipText()}
                    content={getActiveText()}
                    status="active"
                />
            );
        }
        if (
            !property.public_urls?.length &&
            isLessThanTwoMinutesInThePast(
                property.subscription_info?.created_on,
            ) &&
            getSubscriptionStatus() === "active"
        ) {
            return (
                <PropertyStatusBadge
                    tooltipText={getActiveTooltipText()}
                    content={"In Bearbeitung"}
                    status="active"
                />
            );
        }

        // if (getSubscriptionStatus() === "canceled") {
        //     return (
        //         <PropertyStatusBadge
        //             tooltipText={getDeactivatedTooltipComponent()}
        //             content={"Deaktiviert"}
        //             status="inactive"
        //         />
        //     );
        // }
        // return <CircularProgress />;
        // return <PropertyStatusBadge content={"N/A"} status="inactive" />;
    };
    return (
        <Grid item xs={12} sm={6}>
            <Card
                sx={{
                    padding: "1rem",
                    position: "relative",
                    boxShadow: " 1px 4px 8px #aeaeae",
                    mb: "1rem",
                    "&:hover": {
                        boxShadow: " 1px 8px 12px #aeaeae",
                    },
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        display: "flex",
                        top: "0rem",
                        alignItems: "center",
                        fontWeight: 700,
                        right: 0,
                    }}
                >
                    {isMarkedForCancelation && !isCancelled && (
                        <Tooltip
                            title={
                                <Typography>
                                    Ihr Abo läuft am {""}
                                    {property.subscription_info &&
                                        formatDate(
                                            property.subscription_info
                                                .subscription_summary
                                                .current_period_end,
                                        )}{" "}
                                    ab.
                                </Typography>
                            }
                            arrow
                        >
                            <Typography
                                sx={{
                                    padding: "1rem",
                                    backgroundColor: "#cecece",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                Kündigung vorgemerkt
                            </Typography>
                        </Tooltip>
                    )}
                    {isCancelled && (
                        <Tooltip
                            title={
                                <Typography>
                                    Laufzeit-Ende:{" "}
                                    {property.subscription_info &&
                                        formatDate(
                                            property.subscription_info
                                                .subscription_summary
                                                .current_period_end,
                                        )}{" "}
                                    ab.
                                </Typography>
                            }
                            arrow
                        >
                            <Typography
                                sx={{
                                    padding: "1rem",
                                    backgroundColor: "#cecece",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                Abo Abgelaufen
                            </Typography>
                        </Tooltip>
                    )}

                    {!isCancelled &&
                        property.subscription_info &&
                        property.subscription_info?.subscription_summary
                            .remaining_time && (
                            <Typography
                                sx={{
                                    padding: "1rem",
                                    backgroundColor: "#4caf50",
                                    display: "flex",
                                    justifyContent: "center",
                                    color: "#fff",
                                    fontWeight: 700,
                                }}
                            >
                                {`Verbleibende Laufzeit: ${property.subscription_info.subscription_summary.remaining_time}`}
                            </Typography>
                        )}
                </Box>

                <CardContent>
                    <h3>{property.title}</h3>
                    <Box sx={{ display: "flex", mb: "1rem" }}>
                        {property.archived && (
                            <Typography
                                sx={{
                                    padding: "1rem",
                                    backgroundColor: "#cecece",
                                    width: "150px",
                                    display: "flex",
                                    justifyContent: "center",
                                    mr: "1rem",
                                }}
                            >
                                Archiviert
                            </Typography>
                        )}

                        {getPropertyStatusDisplay()}

                        <Box
                            sx={{
                                mr: "1rem",
                                display: "flex",
                                alignItems: "center",
                                gap: ".5rem",
                                ml: "1rem",
                            }}
                        >
                            {property.immonet_public_url && (
                                <Avatar
                                    alt="Immonet"
                                    src={immoNetImg}
                                    className={"platform-logo"}
                                />
                            )}

                            {property.immoscout_public_url && (
                                <Tooltip
                                    title="Inserat auf immoscout24 ansehen"
                                    followCursor
                                    arrow
                                >
                                    <Avatar
                                        alt="ImmoScout"
                                        src={immoScoutImg}
                                        className={"platform-logo"}
                                        onClick={() => {
                                            property.immoscout_public_url &&
                                                window.open(
                                                    property.immoscout_public_url,
                                                );
                                        }}
                                    />
                                </Tooltip>
                            )}

                            {property.immowelt_public_url && (
                                <Tooltip
                                    title="Inserat auf immowelt ansehen"
                                    arrow
                                    followCursor
                                >
                                    <Avatar
                                        alt="ImmoWelt"
                                        src={immoWeltImg}
                                        className={"platform-logo"}
                                        onClick={() => {
                                            property.immowelt_public_url &&
                                                window.open(
                                                    property.immowelt_public_url,
                                                );
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: {
                                xs: "column",
                                md: "row",
                            },
                        }}
                    >
                        <CardMedia
                            component="img"
                            image={imageUrl}
                            alt="PropertyView"
                            sx={{
                                maxWidth: { xs: "100%", md: "150px" },
                                maxHeight: "150px",
                                borderRadius: "4px",
                            }}
                        />

                        <Box
                            sx={{
                                flex: 1,
                            }}
                        >
                            {/* Title Row */}
                            <CardRow sx={{ mb: "1rem", ml: 0 }}>
                                <LocationOnOutlined
                                    sx={{
                                        fontSize: {
                                            xs: "12px",
                                            sm: "16px",
                                            md: "32px",
                                        },
                                    }}
                                />
                                <Typography sx={typographyStyles.card_title}>
                                    {`${property.city}, ${property.post_code}, ${property.country}`}
                                </Typography>
                            </CardRow>
                            <CardRow>
                                <CardRowItem>
                                    <FaEuroSign />{" "}
                                    {property.transaction_type === "sell"
                                        ? "Kaufpreis"
                                        : "Mietpreis"}
                                    :{" "}
                                    {formatPrice(
                                        property.price ||
                                            property.base_rent ||
                                            0,
                                    )}
                                </CardRowItem>{" "}
                                <CardRowItem>
                                    <FaExternalLinkAlt /> Wohnfläche:{" "}
                                    {property.living_space}
                                </CardRowItem>
                            </CardRow>
                            <CardRow>
                                <CardRowItem>
                                    <FaFileWord /> Listing-ID:
                                    {property.id ? 1000 + property.id : "N/A"}
                                </CardRowItem>{" "}
                                <CardRowItem>
                                    <FaDoorOpen /> Anzahl Zimmer:{" "}
                                    {property.room_count}
                                </CardRowItem>
                            </CardRow>
                            <CardRow>
                                <CardRowItem>
                                    <FaClock /> Erstellt:{" "}
                                    {formatDate(property.created_on)}
                                </CardRowItem>{" "}
                                <CardRowItem>
                                    <FaCar /> Anzahl Stellplätze:{" "}
                                    {property.garage_count}
                                </CardRowItem>
                            </CardRow>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        {/* {!property.archived && (
                            <Button
                                sx={{
                                    ...buttonStyles.privateProperty,
                                    flex: 1,
                                    // display: property.archived ? "none" : "block",
                                }}
                                disabled={
                                    loading ||
                                    (!property.is_active && !property.is_paid)
                                }
                                onClick={togglePropertyActiveState}
                            >
                                {loading && (
                                    <CircularProgress
                                        sx={{
                                            mr: "1rem",
                                        }}
                                    />
                                )}
                                {property.is_active
                                    ? labels.deactivate
                                    : labels.activate}
                            </Button>
                        )} */}
                        <Button
                            sx={{
                                ...buttonStyles.privateProperty,
                                flex: 1,

                                display: property.archived ? "none" : "block",
                            }}
                            disabled={property.archived}
                            onClick={() =>
                                navigate(`/property/edit/${property.id}`)
                            }
                        >
                            {labels.edit}
                        </Button>
                        {/* <Button
                            sx={{
                                ...buttonStyles.privateProperty,
                                flex: 1,
                                display: property.archived ? "none" : "block",
                            }}
                            disabled={property.archived}
                            onClick={openArchiveDialog}
                        >
                            {labels.archive}
                        </Button> */}

                        <Button
                            sx={{
                                ...buttonStyles.privateProperty,
                                flex: 1,
                                display: property.archived ? "none" : "block",
                            }}
                            disabled={property.archived}
                            onClick={goToProfile}
                        >
                            Abo ansehen
                        </Button>
                        <Tooltip title={"Sollten Sie Ihr Inserat von den Portalen entfernen wollen, schreiben Sie uns kurz eine Email an: info@selbst-vermarkten.de. In Zukunft können Sie hier auch selbst Ihre Inserate auf den Portalen pausieren."}>

                        <Button
                            sx={{
                                ...buttonStyles.privateProperty,
                                flex: 1,
                                display: property.archived ? "none" : "block",
                                backgroundColor: "#cecece",
                                borderColor: "#cecece",
                                "&:hover":{backgroundColor: "#cecece", borderColor: "#cecece", color: "#fff"}
                            }}

                            // onClick={openSalesHelpDialog}
                            onClick={() =>{}}
                           
                        >
                            {/* {property.agent_handover_requested
                                ? "Hilfe bereits angefordert"
                                : "Hilfe beim Verkauf"} */}
                                Deaktivieren
                        </Button>
                        </Tooltip>
                        <Button
                            sx={{
                                ...buttonStyles.privateProperty,

                                flex: 1,
                                maxWidth: property.archived ? "1px" : "none",
                            }}
                            onClick={() => {
                                navigate(`/property/detail/${property.id}`);
                            }}
                        >
                            Details Anzeigen
                        </Button>
                    </Box>
                </CardContent>

                <DisplayDialog
                    {...dialog}
                    content={
                        dialog.id === "services" && (
                            <ExtraServicesForm
                                extraServices={extraServices}
                                handleCheckboxChange={handleExtraServicesChange}
                            />
                        )
                    }
                />
            </Card>
        </Grid>
    );
};

import { getWithHeader, postWithHeader } from "./base.api";
import { BASE_API_URL } from "./constants";

export const sendMockEmailAPI = async (): Promise<void> => {
    const url = `${BASE_API_URL}/common/send-mock-email`;
    try {
        await getWithHeader(url);
    } catch (error: any) {
        console.error(error?.response?.data);
    }
}
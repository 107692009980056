import { useEffect } from "react";
import { CloseOutlined } from "@mui/icons-material";
import { ListManager } from "react-beautiful-dnd-grid";
import { imageUploaderStyles } from "./imageUploader.styles";
import { usePropertyCreation } from "services/property-creation-context";
import { useDropzone } from "react-dropzone";
import { Box } from "@mui/system";
import { IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { type PreviewImage } from "@interfaces";
import { getPreviewUrls } from "services/img-utils";
import { useParams } from "react-router-dom";
import { usePropertyEdit } from "services/property-edit-context";
import { useDialog } from "services/dialog-context";

const sortPreviews = (list: any) =>
    [...list]
        .sort((first, second) => {
            if (first === 0) return -1;

            return first.id - second.id;
        })
        .filter((item) => item.id !== -1);

// preview  Images have name, previewUrl, id, staticId and file (use this for images before upload)
// these images should be deleted from state if the user refreshes
// images

export const MultiImageUploader = () => {
    const { id } = useParams();
    const {
        property,
        setProperty,
        loadingImages,
        setLoadingImages,
        setPreviewImages: setSortedPreviews,
        previewImages: sortedPreviews,
    } = id ? usePropertyEdit() : usePropertyCreation();
    const {
        isOpen,
        show,
        hide,
        setDialogText,
        setDialogTitle,
        setDialogSeverity,
    } = useDialog();
    // const [sortedPreviews, setSortedPreviews] = useState<PreviewImage[]>([]);

    const generatePreviewUrls = async (previewImages: PreviewImage[]) => {
        try {
            setLoadingImages(true);
            const signedUrlResponse = await getPreviewUrls(previewImages);
            console.log("signed response", signedUrlResponse);
            return signedUrlResponse;
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingImages(false);
        }
    };

    const handleDrop = async (images: File[]) => {
        const prev = sortedPreviews;
        const imagePreviews: PreviewImage[] = [];
        const addedImageNames = prev.map((i) => i.file.name);

        let dialogText = "";
        let dialogHintSize = "";
        let dialogHintExtension = "";
        for (let [idx, img] of images.entries()) {
            const parts = img.name.split(".");
            const extension = parts[parts.length - 1];
            console.log("image parts", parts);
            if (
                !["jpg", "jpeg", "png"].includes(extension) ||
                img.size > 5000000
            ) {
                setDialogTitle("Fehler beim Hinzufügen des Bildes");
                if (img.size > 5000000) {
                    dialogText += `- Das Bild ${img.name} wurde entfernt. (max. Größe überschritten.)\n`;
                    dialogHintSize =
                        "Achten Sie auf eine max. Dateigröße von 5MB. pro Bild.";
                }
                if (!["jpg", "jpeg", "png"].includes(extension)) {
                    dialogText += `- Das Bild ${img.name} wurde entfernt. (unerlaubtes Format: .${extension} )\n`;
                    dialogHintExtension =
                        "Aktuell werden nur Bilder im Format .jpg, .jpeg und .png unterstützt.";
                }
                let fullDialogText =
                    dialogText +
                    "\n" +
                    dialogHintSize +
                    "\n" +
                    dialogHintExtension;
                setDialogText(fullDialogText);
                setDialogSeverity("error");
                show();
                continue;
            }

            const obj = {
                previewUrl: img ? URL.createObjectURL(img) : null,
                id: idx + prev.length,
                staticId: idx + prev.length,
                file: img,
                uploadFields: undefined,
            };
            if (!addedImageNames.includes(img.name)) {
                imagePreviews.push(obj);
                addedImageNames.push(img.name);
            } else {
                alert(
                    `Ein Bild mit dem Namen ${img.name} wurde bereits hinzugefuegt.`,
                );
            }
        }
        const res = await generatePreviewUrls([...prev, ...imagePreviews]);
        setSortedPreviews(res || []);
    };
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleDrop,
    });

    const reorderPreviews = (sourceIndex: any, destinationIndex: any) => {
        setLoadingImages(true);
        try {
            if (destinationIndex === sourceIndex) {
                return;
            }

            const newList = [...sortedPreviews];

            if (destinationIndex === 0) {
                newList[sourceIndex].id = 0;
                newList[0].id = 1;
                setSortedPreviews(sortPreviews(newList));
                return;
            }

            if (destinationIndex === newList.length - 1) {
                newList[sourceIndex].id = newList[newList.length - 1].id + 1;
                setSortedPreviews(sortPreviews(newList));
                return;
            }

            if (destinationIndex < sourceIndex) {
                newList[sourceIndex].id =
                    (newList[destinationIndex].id +
                        newList[destinationIndex - 1].id) /
                    2;
                setSortedPreviews(sortPreviews(newList));
                return;
            }

            newList[sourceIndex].id =
                (newList[destinationIndex].id +
                    newList[destinationIndex + 1].id) /
                2;
            setSortedPreviews(sortPreviews(newList));
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingImages(false);
        }
    };
    const handleRemoveImage = (index: number) => {
        const tempImages = sortedPreviews;
        const beforeImages = tempImages?.slice(0, index);
        const afterImages = tempImages?.slice(index + 1);
        const concattedImages = [...beforeImages, ...afterImages];
        setSortedPreviews(concattedImages);
        setProperty((prev) => ({ ...prev, previewImages: concattedImages }));
    };
    const isSmallScreen = useMediaQuery("(max-width:500px)");

    return (
        <div>
            <Box sx={imageUploaderStyles.uploader} {...getRootProps()}>
                <input {...getInputProps()} />
                <Typography>Datei hier ablegen oder hier klicken</Typography>
            </Box>
            {true && (
                <ListManager
                    items={sortedPreviews.map((preview, idx) => ({
                        id: preview.id.toString(),
                        order: preview.id,
                        previewUrl: preview.previewUrl,
                        staticId: idx,
                    }))}
                    direction={isSmallScreen ? "vertical" : "horizontal"}
                    maxItems={8}
                    render={(item) => (
                        <ListElement
                            item={item}
                            handleRemoveImage={handleRemoveImage}
                        />
                    )}
                    onDragEnd={reorderPreviews}
                />
            )}
        </div>
    );
};

const IndexDisplay = ({ index }: { index: number }) => {
    const { customColors } = useTheme();
    return (
        <Box
            sx={{
                ...imageUploaderStyles.indexDisplay,
                backgroundColor: customColors.colorPrimary,
            }}
        >
            {index + 1}
        </Box>
    );
};

const ListElement = ({
    item,
    handleRemoveImage,
}: {
    item: any;
    handleRemoveImage: (index: number) => void;
}) => {
    return (
        <Box
            sx={{
                zIndex: 999,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <IndexDisplay index={Number(item.staticId)} />
            <img
                style={imageUploaderStyles.img}
                src={item ? item.previewUrl : undefined}
                alt="preview"
            />
            <IconButton
                sx={{
                    border: "1px solid #000",
                    borderRadius: "999px",
                    padding: "2px",
                    color: "#000",
                    transition: "300ms",
                    "&:hover": {
                        color: "red",
                        borderColor: "red",
                    },
                }}
                onClick={() => {
                    handleRemoveImage(item.staticId);
                }}
            >
                <CloseOutlined />
            </IconButton>
        </Box>
    );
};

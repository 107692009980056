import { Box, Button, Typography } from "@mui/material";
import { IS_DEV, useApp } from "services";
import { useNavigate } from "react-router-dom";
import { PrivatePropertySummary } from "components";

const PropertyListPage = () => {
    const navigate = useNavigate();
    const { privateProperties: properties, fetchPrivateProperties } = useApp();
    const loggedInUser = localStorage.getItem("user");
    console.log("IS_DEV", IS_DEV);

    return (
        <>
            <Typography
                variant="h4"
                gutterBottom
                sx={{ fontSize: { xs: "1rem", sm: "1.5rem", xl: "2rem" } }}
            >
                Meine Immobilien
            </Typography>
            {properties && properties.length ? (
                <Box>
                    {properties.map((p) => (
                        <PrivatePropertySummary
                            key={p.id}
                            property={p}
                            isOwner={p.user?.toString() === loggedInUser}
                            refetchProperties={fetchPrivateProperties}
                        />
                    ))}
                </Box>
            ) : (
                <Box>
                    <Typography>
                        Sie haben noch keine Immobilien inseriert.
                    </Typography>
                    <Button onClick={() => navigate("/property/new")}>
                        Jetzt inserieren
                    </Button>
                </Box>
            )}
        </>
    );
};

export default PropertyListPage;
